.input-wrapper {
  // margin: 12px 24px 12px 24px;
  // width: 100%;

  .input-component {
    height: 4.8rem;
    width: 100%;
    padding: 1rem;
    font-size: 1.8rem;
    border-radius: 4px;
    border: 2px solid $border-medium;

    &::placeholder {
      // size: 0.5rem;
      color: #9899a0;
    }
  }
}

.dropdown-wrapper {
    .dropdown-component {
        height: 4.8rem;
        width: 100%;
        padding: 1rem;
        border-radius: 4px;
        border: 2px solid $border-medium;
        margin: .5rem 0;

        &::placeholder {
            // size: 0.5rem;
            color: #9899A0;
        }

        &__l {
            font-size: 1.8rem;
        }
        &__s {
            font-size: 1.2rem;
        }
    }
}

// .dropdown {
//   width: 100%;
//   border-radius: 10px;
//   box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
//   background-color: white;
//   position: relative;
//   max-height: 47px;

//   &__l {
//     font-size: 1.8rem;
//   }

//   &__s {
//     font-size: 1.2rem;
//   }

//   .dropdown-header {
//     padding: 15px;
//     cursor: pointer;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     position: relative;
//     // max-height: 47px;

//     .icon {
//       font-size: 13px;
//       color: #91a5be;
//       transform: rotate(0deg);
//       transition: all 0.2s ease-in-out;
//     }

//     .icon.open {
//       transform: rotate(90deg);
//     }
//   }

//   .dropdown-body {
//     padding: 5px;
//     border-top: 1px solid #e5e8ec;
//     display: none;
//     max-height: 200px;
//     overflow-y: auto;
//     position: absolute;
//     top: 40px;
//     z-index: 9999;
//     background-color: white;
//     width: 100%;

//     box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
//     .dropdown-item {
//       padding: 10px 10px;

//       &:hover {
//         cursor: pointer;
//       }
//       .dropdown-item-dot {
//         opacity: 0;
//         color: #91a5be;
//         transition: all 0.2s ease-in-out;
//       }
//       .dropdown-item-dot.selected {
//         opacity: 1;
//       }

//       &__selected {
//         background-color: rgba($color: $color-n11, $alpha: 0.1);
//       }
//     }
//   }
// }

//   .dropdown-body.open {
//     display: block;
//   }
