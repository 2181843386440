.data-screen {
  &__header {
    min-height: 28rem;
    display: flex;
    flex-direction: row;

    @include respond(phone) {
      flex-direction: column;
      align-items: center;
    }

    &_main {
      width: auto;
      // width: 18rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 23.5px 0 0 0;
      @include respond(tab-port) {
        justify-content: center;
      }
      @include respond(phone) {
        align-items: center;
      }

      div:nth-child(2) {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-around;
        @include respond(tab-port) {
          flex: 0;
        }
      }

      &__dropdowns-wrapper {
        @include respond(phone) {
          margin: 40px 0;
        }
      }
    }

    &_data {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      padding: 0 10rem;

      @include respond(tab-port) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(15rem, auto);
      }
      @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        padding: 0;
        grid-auto-rows: minmax(5rem, auto);
      }
      .indicator-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__title {
          min-height: 5rem;
          // justify-content: flex-end;
          @include respond(960px) {
            min-height: 6rem;
          }

          @include respond(phone) {
            min-height: 0;
          }
        }
        &__data {
          font-size: 4.5rem;
          color: $color-n11;
          &__secodnary {
            color: $color-tertiary-light;
          }
          @include respond(phone) {
            font-size: 3rem;
          }
        }
      }
    }
  }

  .charts-container {
    padding: 20px 0;
    background-color: blue;
  }
}
