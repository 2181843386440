.header {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
    position: sticky;
    top: 0;
    z-index: 9999;

    .logout {
        cursor: pointer;
    }
}