.toggle-btn {
    display: flex;
    justify-content: flex-end;
    margin: 8rem 2rem 4rem 0;
    .toggle-label {
      display: flex;
      align-items: center;
      margin: 0 2rem;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 6rem;
        height: 3rem;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 2.5rem;
        width: 2.5rem;
        left: .3rem;
        bottom: .3rem;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: $color-n11;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px $color-n11;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      
}