body {
  // font-family: "Lato", sans-serif;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: $color-grey-dark;
}

p {
  font-size: $default-font-size;
  color: $color-black;
}

.heading-primary {
  color: $color-black;
  text-transform: uppercase;

  backface-visibility: hidden;
  font-weight: 700;
  font-size: 3.2rem;

  &--main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone) {
      letter-spacing: 1rem;
      font-family: 5rem;
    }
    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out;

    @include respond(phone) {
      letter-spacing: 0.5rem;
    }
  }
}

.heading-secondary {
  font-size: $default-font-size;
  font-weight: 500;
  font-size: 3rem;
  line-height: 3.8rem;
  color: $color-black;
}

.paragraph {
  &__l {
    font-size: 1.6rem;
  }
  &__s {
    font-size: 1.2rem;
  }

  &__center {
    align-self: center;
    text-align: center;
  }

  &__grey {
    color: $color-grey;
  }
  &__red {
    color: $color-n11;
  }

  &__b {
    font-weight: bold;
  }
}
