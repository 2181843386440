.u-center-text { text-align: center !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-big { 
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
}
.center-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.abs {
    position: absolute;
    &__r {
        right: 0;
    }
}

.rotate{
    &__90 {
        transform: rotate(90deg);
    }
    &__180 {
        transform: rotate(180deg);
    }
    &__270 {
        transform: rotate(270deg);
    }
}