*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) { // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) { // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
    
    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

body {
    box-sizing: border-box;

    @include respond(tab-port) {
        padding: 0;
    }
}


.layout {
    min-height: 100vh;
    max-width: 1500px;
    margin: 0 auto;

    .logout-btn {
        font-size: 1.5rem;
        color: #E5004D;
        font-weight: 700;
    }
}

.screen {
    padding: 0 48px;

    @include respond(big-desktop) {
        padding: 0 96px; //1rem = 12, 12/16
    }

    @include respond(tab-port) { // width < 900?
        padding: 0 24px; //1 rem = 8px, 8/16 = 50%
    }
    @include respond(phone) { // width < 900?
        padding: 0; //1 rem = 8px, 8/16 = 50%
    }
}

::selection {
    background-color: $color-n11;
    color: $color-white;
}