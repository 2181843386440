.login-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-container {
        height: 64rem;
        width: 43rem;
        transform: translateY(-6%);

        .logo-container {
            height: 14rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 5rem;
            }
        }

        .form-container {
            height: 50rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding: 0 2.4rem ;

            .form-welcome-container {
                height: 11rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid $border-light;
            }

            .form-inputs-container {
                height: 39rem;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
            }

            .login-button-wrapper {
                position: relative;
                height: 50px;
                .login-button-container {
                    position: absolute;
                    right: 0;
                    top: 30%;
                    
                    .login-text {
                        color: $color-n11;
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    .login-button {
                        background-color: $color-white;
                        width: 6rem;
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                    }
                }
            }
        }
    }
    
}