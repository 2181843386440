.triangle {
    &--s {
        width: 0;
        height: 0;
        border-style: solid;
        border-width:  0 5px 8px 5px;
        border-color: transparent transparent $color-n11 transparent;
    }

    &--right {
        transform: rotate(90deg);
    }
}